import { ErrorBoundary } from "components/error-boundary/error-boundary";
import { AdminPanel } from "admin-panel";
import ReactDOM from "react-dom";
import React from "react";
import { runtimeConfig } from "runtime-config";

// eslint-disable-next-line no-console -- We want to log the version
console.log("App version: ", runtimeConfig.appVersion);

ReactDOM.render(
  <ErrorBoundary>
    <AdminPanel />
  </ErrorBoundary>,
  document.getElementById("root")
);
