import { InfoRow } from "components/info-grid/info-row";
import { useFeaturesContext } from "contexts/features/features-context";
import React from "react";

export function FeatureInfoRow(): JSX.Element {
  const featuresContext = useFeaturesContext();

  const { selectedFeatureOrBundleId, getFeatureOrBundleForId } =
    featuresContext;

  return (
    <InfoRow
      labelTextAlign="left"
      label="Product"
      info={getFeatureOrBundleForId(selectedFeatureOrBundleId)?.name}
    />
  );
}
