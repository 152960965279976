import { APITypes, AdminAPITypes } from "@stellar/api-logic";

// TODO: Combine getFeatureOrBundleFromId and getFeatureOrBundleFromIdentifier functions into one
/**
 * Gets the feature or bundle from the list of features and bundles based upon the selected feature or bundle id
 */
export function getFeatureOrBundleFromId(
  features: AdminAPITypes.IFeature[],
  featureBundles: AdminAPITypes.IFeatureBundle[],
  selectedFeatureOrBundleId:
    | AdminAPITypes.FeatureId
    | AdminAPITypes.FeatureBundleId
): AdminAPITypes.IFeature | AdminAPITypes.IFeatureBundle | null {
  return (
    [...features, ...featureBundles].find(
      (featureOrBundle) => featureOrBundle.id === selectedFeatureOrBundleId
    ) ?? null
  );
}

/**
 * Gets the feature or bundle from the list of features and bundles based upon the selected feature or bundle identifier
 */
export function getFeatureOrBundleFromIdentifier(
  features: AdminAPITypes.IFeature[],
  featureBundles: AdminAPITypes.IFeatureBundle[],
  selectedFeatureOrBundleIdentifier:
    | AdminAPITypes.EFeatureBundleIdentifier
    | APITypes.EUserSubscriptionRole
): AdminAPITypes.IFeature | AdminAPITypes.IFeatureBundle | null {
  return (
    [...features, ...featureBundles].find(
      (featureOrBundle) =>
        featureOrBundle.identifier === selectedFeatureOrBundleIdentifier
    ) ?? null
  );
}
