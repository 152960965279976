import { IFeaturesContext } from "contexts/features/features-context-types";
import { AdminAPITypes } from "@stellar/api-logic";
import { DateTimeUtils } from "@stellar/web-core";
import { isFeatureBundle } from "utils/type-guards";
import { IPlanCreationContext } from "./plan-creation-context-types";
import { removeCurrentFieldFromConstraints } from "utils/constraint";

/**
 * Convert the PlanCreationContext into an AdminAPITypes.CreatePlanPayload object
 */
export function createPlanCreationPayload(
  planCreationContext: IPlanCreationContext,
  featuresContext: IFeaturesContext
): AdminAPITypes.CreatePlanPayload {
  const {
    endDate,
    shouldAddToExistingStripeSubscription,
    existingStripeSubscriptionId,
    planSubjectType,
    planSubjectId,
    activeConstraints,
    startDate,
    comments,
  } = planCreationContext;

  const { selectedFeatureOrBundleId, getFeatureOrBundleForId } =
    featuresContext;

  let payment: AdminAPITypes.CreatePlanPaymentInformation;

  if (shouldAddToExistingStripeSubscription) {
    payment = {
      existing: {
        paymentId: existingStripeSubscriptionId,
      },
    };
  } else {
    if (!startDate) {
      throw new Error(
        "createPlanCreationPayload: startDate must be set for unpaid plans"
      );
    }
    if (!endDate) {
      throw new Error(
        "createPlanCreationPayload: endDate must be set for unpaid plans"
      );
    }

    payment = {
      unpaid: {
        startDate: DateTimeUtils.getStartOfDay({
          date: startDate,
          shouldUseUtc: true,
        }).toISOString(),

        endDate: DateTimeUtils.getEndOfDay({
          date: endDate,
          shouldUseUtc: true,
        }).toISOString(),
      },
    };
  }
  // Remove 'current' field from each constraint in activeConstraints
  const cleanedActiveConstraints =
    removeCurrentFieldFromConstraints(activeConstraints);
  // TODO: Think about moving this feature logic somewhere else
  // because createPlanCreationPayload should not be concerned with figuring out
  // if the selected "product" is a feature or a bundle
  const selectedFeatureOrBundle = getFeatureOrBundleForId(
    selectedFeatureOrBundleId
  );

  const selectedFeatureBundleIds: string[] = [];
  const selectedFeatureIds: string[] = [];

  if (selectedFeatureOrBundle) {
    if (isFeatureBundle(selectedFeatureOrBundle)) {
      selectedFeatureBundleIds.push(selectedFeatureOrBundle.id);
    } else {
      selectedFeatureIds.push(selectedFeatureOrBundle.id);
    }
  }

  return {
    subject: {
      type: planSubjectType,
      id:
        // There are two methods for creating device licenses:
        // one where admins provide a specific serial number, and another for any device without a serial number,
        // which uses auto-binding later.For these subscriptions, a special string '??-' is used as the serial number;
        // creating the subscription with subject ID '??-'.
        planSubjectType === AdminAPITypes.ESubjectType.device
          ? "??-"
          : planSubjectId,
    },

    constraints: cleanedActiveConstraints,

    featureBundles: selectedFeatureBundleIds,
    features: selectedFeatureIds,

    // As we will simple use the feature name for displaying the plan, value of label does not matter
    label: "",

    description: comments,

    payment,
  };
}
