import { useEffect, useState } from "react";
import { AdminAPITypes } from "@stellar/api-logic";
import { CoreApi } from "api";
import { ErrorHandlingFunction } from "components/error-boundary/error-handling-context-types";

/** List of all allowed feature bundles in Admin Panel */
const allAllowedBundles: AdminAPITypes.EFeatureBundleIdentifier[] = [
  AdminAPITypes.EFeatureBundleIdentifier.dashboard,
  AdminAPITypes.EFeatureBundleIdentifier.education,
  AdminAPITypes.EFeatureBundleIdentifier.planFm,
  AdminAPITypes.EFeatureBundleIdentifier.trial,
  AdminAPITypes.EFeatureBundleIdentifier.premiumInsights,
  AdminAPITypes.EFeatureBundleIdentifier.gps,
  AdminAPITypes.EFeatureBundleIdentifier.snapshot,
  AdminAPITypes.EFeatureBundleIdentifier.vpm,
  AdminAPITypes.EFeatureBundleIdentifier.pointCloud,
  AdminAPITypes.EFeatureBundleIdentifier.vpmEnterprise,
  AdminAPITypes.EFeatureBundleIdentifier.cloudProcessing,
  AdminAPITypes.EFeatureBundleIdentifier.cloudProcessingTrial,
  AdminAPITypes.EFeatureBundleIdentifier.cad,
  AdminAPITypes.EFeatureBundleIdentifier.flash,
  AdminAPITypes.EFeatureBundleIdentifier.faroStream,
];

/**
 * Use this hook to fetch all the feature bundles and filter them out based upon the allowed ones
 * Returns the allowed feature bundles for a subject type and a loading flag.
 * If the loading fails, the fetched feature bundles will be an empty array.
 */
export function useBundlesOfSubjectType(
  planSubjectType: AdminAPITypes.ESubjectType,
  errorHandler: ErrorHandlingFunction
): [AdminAPITypes.IFeatureBundle[], boolean] {
  const [allowedBundlesOfSubjectType, setAllowedBundlesOfSubjectType] =
    useState<AdminAPITypes.IFeatureBundle[]>([]);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    async function fetchFeatureBundles(): Promise<void> {
      setIsFetching(true);

      try {
        const allBundles = await CoreApi.V0.ADMIN.getAllFeatureBundles();
        setAllowedBundlesOfSubjectType(
          allBundles?.filter(
            (bundle) =>
              allAllowedBundles.includes(bundle.identifier) &&
              isBundleAssignableToSubjectType(bundle, planSubjectType)
          )
        );
      } catch (error) {
        errorHandler("Could not load Feature Bundles", error);
        setAllowedBundlesOfSubjectType([]);
      } finally {
        setIsFetching(false);
      }
    }

    fetchFeatureBundles();
  }, [errorHandler, planSubjectType]);

  return [allowedBundlesOfSubjectType, isFetching];
}

function isBundleAssignableToSubjectType(
  feature: AdminAPITypes.IFeatureBundle,
  planSubjectType: AdminAPITypes.ESubjectType
): boolean {
  switch (planSubjectType) {
    case AdminAPITypes.ESubjectType.company:
      return feature.scopes.includes("company");
    case AdminAPITypes.ESubjectType.project:
      return feature.scopes.includes("project");
    case AdminAPITypes.ESubjectType.device:
      return feature.scopes.includes("device");
    case AdminAPITypes.ESubjectType.user:
      return feature.scopes.includes("user");
  }
  return false;
}
