import { useEffect, useState } from "react";
import { AdminAPITypes, APITypes } from "@stellar/api-logic";
import { CoreApi } from "api";
import { ErrorHandlingFunction } from "components/error-boundary/error-handling-context-types";

/** List of all allowed features in Admin Panel */
const allAllowedFeatures: APITypes.EUserSubscriptionRole[] = [
  APITypes.EUserSubscriptionRole.globalFaceBlurring,
  APITypes.EUserSubscriptionRole.ft_spotwalk,
  APITypes.EUserSubscriptionRole.ft_download_360,
  APITypes.EUserSubscriptionRole.ft_project_evaluation,
  APITypes.EUserSubscriptionRole.projectProgress,
  APITypes.EUserSubscriptionRole.insightsTeaser,
  APITypes.EUserSubscriptionRole.globalVideoMode,
  APITypes.EUserSubscriptionRole.globalProgressAi,
  APITypes.EUserSubscriptionRole.globalOpenInSphereViewer,
  APITypes.EUserSubscriptionRole.globalPointCloudSendTo,
  APITypes.EUserSubscriptionRole.sphereDashboard,
];

/**
 * Use this hook to fetch all the features and filter them out based upon the allowed ones
 * Returns the allowed features for a subject type and a loading flag.
 * If the loading fails, the fetched features will be an empty array.
 */
export function useFeaturesOfSubjectType(
  planSubjectType: AdminAPITypes.ESubjectType,
  errorHandler: ErrorHandlingFunction
): [AdminAPITypes.IFeature[], boolean] {
  const [allowedFeaturesOfSubjectType, setAllowedFeaturesOfSubjectType] =
    useState<AdminAPITypes.IFeature[]>([]);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    async function fetchFeatures(): Promise<void> {
      setIsFetching(true);

      try {
        const allFeatures = await CoreApi.V0.ADMIN.getAllFeatures();
        setAllowedFeaturesOfSubjectType(
          allFeatures?.filter(
            (feature) =>
              allAllowedFeatures.includes(feature.identifier) &&
              isFeatureAssignableToSubjectType(feature, planSubjectType)
          )
        );
      } catch (error) {
        errorHandler("Could not load Features", error);
        setAllowedFeaturesOfSubjectType([]);
      } finally {
        setIsFetching(false);
      }
    }

    fetchFeatures();
  }, [errorHandler, planSubjectType]);

  return [allowedFeaturesOfSubjectType, isFetching];
}

function isFeatureAssignableToSubjectType(
  feature: AdminAPITypes.IFeature,
  planSubjectType: AdminAPITypes.ESubjectType
): boolean {
  switch (planSubjectType) {
    case AdminAPITypes.ESubjectType.company:
      return feature.scopes.includes("company");
    case AdminAPITypes.ESubjectType.project:
      return feature.scopes.includes("project");
    case AdminAPITypes.ESubjectType.device:
      return feature.scopes.includes("device");
    case AdminAPITypes.ESubjectType.user:
      return feature.scopes.includes("user");
  }
  return false;
}
