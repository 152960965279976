import { AdminAPITypes } from "@stellar/api-logic";
import React, { useContext } from "react";
import {
  IFeaturesContext,
  FeatureAndBundleGetter,
} from "./features-context-types";

/**
 * Returns a function that can be used to get a feature and feature bundle by ID
 * Useful when a given ID can be either of a feature or feature bundle,
 * which is the case for the product dropdown in the plan creation form
 *
 * This should be used to generate a function passed into the FeaturesContext as getFeatureOrBundleForId
 * for the given features and featureBundles
 */
export function createFeatureAndBundleGetter(
  features: AdminAPITypes.IFeature[],
  featureBundles: AdminAPITypes.IFeatureBundle[]
): FeatureAndBundleGetter {
  return function getFeatureOrBundleForId(
    featureOrBundleId: AdminAPITypes.FeatureId | AdminAPITypes.FeatureBundleId
  ): AdminAPITypes.IFeature | AdminAPITypes.IFeatureBundle | undefined {
    return [...features, ...featureBundles].find(
      (featureOrBundle) => featureOrBundle.id === featureOrBundleId
    );
  };
}

export const FeaturesContext =
  React.createContext<IFeaturesContext | null>(null);

export function useFeaturesContext(): IFeaturesContext {
  const context = useContext(FeaturesContext);

  if (!context) {
    throw new Error(
      "useFeaturesContext() has to be used within a FeaturesContext."
    );
  }

  return context;
}
