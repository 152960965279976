import { useFeaturesContext } from "contexts/features/features-context";
import { AdminAPITypes, APITypes } from "@stellar/api-logic";
import { isFeatureBundle } from "utils/type-guards";

/**
 * Use this hook to decide if the plan, the user is currently creating, should have limits
 * E.g. project plans and some features should not have plan limits
 */
export function useShouldPlanHaveLimits(
  planSubjectType: AdminAPITypes.ESubjectType
): boolean {
  const featuresContext = useFeaturesContext();
  // For subject of type 'device', the plan limit form should not be displayed.
  if (planSubjectType === AdminAPITypes.ESubjectType.device) {
    return false;
  }

  const { features, featureBundles, selectedFeatureOrBundleId } =
    featuresContext;

  const selectedFeatureOrBundle = [...features, ...featureBundles].find(
    (featureOrBundle) => featureOrBundle.id === selectedFeatureOrBundleId
  );

  // Hide the plan limits if the plan creation is for a specific feature or bundle
  // List of features or bundles without plan limit: https://bitstars.atlassian.net/browse/ARWEB-17336
  if (selectedFeatureOrBundle) {
    return !isFeatureOrBundleWithoutLimit(
      planSubjectType,
      selectedFeatureOrBundle
    );
  }
  return true;
}

function isFeatureOrBundleWithoutLimit(
  planSubjectType: AdminAPITypes.ESubjectType,
  featureOrBundle: AdminAPITypes.IFeature | AdminAPITypes.IFeatureBundle
): boolean {
  if (isFeatureBundle(featureOrBundle)) {
    if (
      getBundleIdentifiersWithoutLimitsForSubjectType(planSubjectType).includes(
        featureOrBundle.identifier
      )
    ) {
      return true;
    }
  } else if (
    getFeatureIdentifiersWithoutLimitsForSubjectType(planSubjectType).includes(
      featureOrBundle.identifier
    )
  ) {
    return true;
  }
  return false;
}

/**
 * Get all the features that don't need the limits based upon plan subject type
 */
function getFeatureIdentifiersWithoutLimitsForSubjectType(
  planSubjectType: AdminAPITypes.ESubjectType
): APITypes.EUserSubscriptionRole[] {
  const featureIdentifiersWithoutLimits: APITypes.EUserSubscriptionRole[] = [
    APITypes.EUserSubscriptionRole.globalFaceBlurring,
    APITypes.EUserSubscriptionRole.ft_download_360,
    APITypes.EUserSubscriptionRole.ft_project_evaluation,
    APITypes.EUserSubscriptionRole.projectProgress,
    APITypes.EUserSubscriptionRole.insightsTeaser,
    APITypes.EUserSubscriptionRole.globalVais,
    APITypes.EUserSubscriptionRole.globalVideoMode,
    APITypes.EUserSubscriptionRole.globalProgressAi,
    APITypes.EUserSubscriptionRole.globalOpenInSphereViewer,
    APITypes.EUserSubscriptionRole.globalPointCloudSendTo,
    APITypes.EUserSubscriptionRole.sphereDashboard,
    APITypes.EUserSubscriptionRole.cadImport,
    APITypes.EUserSubscriptionRole.cadDisplay,
  ];

  if (planSubjectType === AdminAPITypes.ESubjectType.project) {
    featureIdentifiersWithoutLimits.push(
      APITypes.EUserSubscriptionRole.ft_spotwalk,
      APITypes.EUserSubscriptionRole.ft_material_ai,
      APITypes.EUserSubscriptionRole.material_ai_processing_only
    );
  }

  return featureIdentifiersWithoutLimits;
}

/**
 * Get all the bundles that don't need the limits based upon plan subject type
 */
function getBundleIdentifiersWithoutLimitsForSubjectType(
  planSubjectType: AdminAPITypes.ESubjectType
): AdminAPITypes.EFeatureBundleIdentifier[] {
  const bundleIdentifiersWithoutLimits: AdminAPITypes.EFeatureBundleIdentifier[] =
    [
      AdminAPITypes.EFeatureBundleIdentifier.trial,
      AdminAPITypes.EFeatureBundleIdentifier.gps,
      AdminAPITypes.EFeatureBundleIdentifier.snapshot,
      AdminAPITypes.EFeatureBundleIdentifier.pointCloud,
      AdminAPITypes.EFeatureBundleIdentifier.cloudProcessing,
      AdminAPITypes.EFeatureBundleIdentifier.cloudProcessingTrial,
      AdminAPITypes.EFeatureBundleIdentifier.cad,
    ];

  switch (planSubjectType) {
    case AdminAPITypes.ESubjectType.company:
      bundleIdentifiersWithoutLimits.push(
        AdminAPITypes.EFeatureBundleIdentifier.faceBlurring,
        AdminAPITypes.EFeatureBundleIdentifier.download360,
        AdminAPITypes.EFeatureBundleIdentifier.projectEvaluations,
        AdminAPITypes.EFeatureBundleIdentifier.premiumInsights,
        AdminAPITypes.EFeatureBundleIdentifier.dashboard,
        AdminAPITypes.EFeatureBundleIdentifier.vpmEnterprise
      );
      break;
    case AdminAPITypes.ESubjectType.project:
      bundleIdentifiersWithoutLimits.push(
        AdminAPITypes.EFeatureBundleIdentifier.education,
        AdminAPITypes.EFeatureBundleIdentifier.planFm
      );
      break;
  }

  return bundleIdentifiersWithoutLimits;
}
