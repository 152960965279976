import { AdminAPITypes } from "@stellar/api-logic";
import React, { useEffect, useState } from "react";
import { CreatePlanForm } from "./create-plan-form";
import { PlanCreationContext } from "../plan-creation-context";
import { PlanCreationSummary } from "./plan-creation-summary";
import { Constraint } from "utils/constraint";
import { NavigationWarning } from "components/navigation-warning";
import {
  createFeatureAndBundleGetter,
  FeaturesContext,
} from "contexts/features/features-context";
import { LoadingScreen } from "components/loading-screen";
import { useFeaturesOfSubjectType } from "utils/hooks/use-allowed-features-of-subject-type";
import { useBundlesOfSubjectType } from "utils/hooks/use-allowed-bundles-of-subject-type";
import { useErrorHandler } from "components/error-boundary/error-handling-context";
import { HBSpinner } from "components/hb-customs/hb-spinner";
import { Box } from "@mui/material";

interface IProps {
  planSubjectId: string;
  planSubjectType: AdminAPITypes.ESubjectType;
  planSubjectName: string;
}

export function CreatePlanContainer({
  planSubjectType,
  planSubjectName,
  planSubjectId,
}: IProps): JSX.Element {
  const { handleErrorWithDialog } = useErrorHandler();

  const [
    shouldAddToExistingStripeSubscription,
    setShouldAddToExistingStripeSubscription,
  ] = useState<boolean>(false);
  const [existingStripeSubscriptionId, setExistingStripeSubscriptionId] =
    useState<string>("");

  const [activeConstraints, setActiveConstraints] = useState<
    AdminAPITypes.CreateConstraintPayload[]
  >([]);

  const [comments, setComments] = useState<string>("");

  // Setting initial startDate & endDate to "now" because the time of the day does not matter at this point and will
  // be adjusted before creating the plan through API.
  // Using start/end of day at this point can cause problems because of different time zones vs. UTC and adds
  // to much confusion in general.
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());

  // No date is required for plans linked to existing Stripe subs as the dates will be provided by those subs
  const isDateRequired = !shouldAddToExistingStripeSubscription;

  const [selectedFeatureOrBundleId, setSelectedFeatureOrBundleId] =
    useState<string>("");

  const [features, isFetchingFeatures] = useFeaturesOfSubjectType(
    planSubjectType,
    handleErrorWithDialog
  );

  const [featureBundles, isFetchingFeatureBundles] = useBundlesOfSubjectType(
    planSubjectType,
    handleErrorWithDialog
  );

  const [isFetchingData, setIsFetchingData] = useState<boolean>(false);
  const [shouldShowSummary, setShouldShowSummary] = useState<boolean>(false);

  // Filter out constraints with zero or no value
  useEffect(() => {
    if (shouldShowSummary) {
      setActiveConstraints((activeConstraints) =>
        removeEmptyConstraints(activeConstraints)
      );
    }
  }, [shouldShowSummary]);

  if (isFetchingData || isFetchingFeatures || isFetchingFeatureBundles) {
    return <HBSpinner />;
  }

  return (
    <LoadingScreen isFetching={isFetchingData}>
      <PlanCreationContext.Provider
        value={{
          planSubjectId,
          planSubjectType,
          planSubjectName,
          shouldAddToExistingStripeSubscription,
          setShouldAddToExistingStripeSubscription,
          existingStripeSubscriptionId,
          setExistingStripeSubscriptionId,
          activeConstraints,
          setActiveConstraints,
          comments,
          setComments,
          startDate,
          setStartDate,
          endDate,
          setEndDate,
          isDateRequired,
          isFetchingData,
          setIsFetchingData,
          shouldShowSummary,
          setShouldShowSummary,
        }}
      >
        <FeaturesContext.Provider
          value={{
            selectedFeatureOrBundleId,
            setSelectedFeatureOrBundleId,
            features,
            featureBundles,
            getFeatureOrBundleForId: createFeatureAndBundleGetter(
              features,
              featureBundles
            ),
          }}
        >
          <NavigationWarning />

          <Box sx={{ margin: "10px 110px 0px" }}>
            {shouldShowSummary ? <PlanCreationSummary /> : <CreatePlanForm />}
          </Box>
        </FeaturesContext.Provider>
      </PlanCreationContext.Provider>
    </LoadingScreen>
  );
}

function removeEmptyConstraints(
  constraints: AdminAPITypes.CreateConstraintPayload[]
): AdminAPITypes.CreateConstraintPayload[] {
  return constraints.filter((constraint) => {
    return Constraint.getMaxValueOfConstraint(constraint) > 0;
  });
}
