import { AdminAPITypes, CoreAPITypes } from "@stellar/api-logic";
import {
  IAccountProjectCreation,
  ICompanyProjectCreation,
} from "pages/create-project/project-creation-context-types";

// TODO: isCompanyContext and isUserContext functions should be removed in a new ticket.
// The User type is obsolete( Previously, Projects could exist without a workspace or company
// were associated with a single user account, but this structure is not used anymore).
// Currently, every context always includes a defined 'Company' object.

export function isCompanyContext(
  context:
    | AdminAPITypes.IAdmProjectCompanyContext
    | AdminAPITypes.IAdmProjectUserContext
): context is AdminAPITypes.IAdmProjectCompanyContext {
  return context.kind === "COMPANY";
}

export function isUserContext(
  context:
    | AdminAPITypes.IAdmProjectCompanyContext
    | AdminAPITypes.IAdmProjectUserContext
): context is AdminAPITypes.IAdmProjectUserContext {
  return context.kind === "USER";
}

export function isUnpaidPlan(
  plan: AdminAPITypes.IPlan
): plan is AdminAPITypes.IUnpaidPlan {
  return !!(plan?.payment as AdminAPITypes.IUnpaidPaymentInformation)?.unpaid;
}

export function isRecurringPlan(
  plan: AdminAPITypes.IPlan
): plan is AdminAPITypes.IRecurringPlan {
  return !!(plan?.payment as AdminAPITypes.IRecurringPaymentInformation)
    ?.recurring;
}

export function isCreateProjectForAccount(
  subject: IAccountProjectCreation | ICompanyProjectCreation
): subject is IAccountProjectCreation {
  return subject.type === AdminAPITypes.ESubjectType.user;
}

export function isCreateProjectAreaConstraintPayload(
  createConstraintPayload: AdminAPITypes.CreateConstraintPayload
): createConstraintPayload is AdminAPITypes.CreateProjectAreaConstraintPayload {
  return (
    createConstraintPayload.type === AdminAPITypes.EConstraintType.projectArea
  );
}

export function isCreateProjectCountConstraintPayload(
  createConstraintPayload: AdminAPITypes.CreateConstraintPayload
): createConstraintPayload is AdminAPITypes.CreateProjectCountConstraintPayload {
  return (
    createConstraintPayload.type === AdminAPITypes.EConstraintType.projectCount
  );
}

export function isCreateUserCountConstraintPayload(
  createConstraintPayload: AdminAPITypes.CreateConstraintPayload
): createConstraintPayload is AdminAPITypes.CreateUserCountConstraintPayload {
  return (
    createConstraintPayload.type === AdminAPITypes.EConstraintType.userCount
  );
}

/**
 * Returns true if the argument is in the type of Waypoint Constraint payloads
 */
export function isCreateWaypointConstraintPayload(
  createConstraintPayload: AdminAPITypes.CreateConstraintPayload
): createConstraintPayload is AdminAPITypes.CreateWaypointConstraintPayload {
  return (
    createConstraintPayload.type === AdminAPITypes.EConstraintType.waypoint
  );
}

/**
 * Returns true if the argument is in the type of Timetravel on Waypoint Constraint payloads
 */
export function isCreateTimetravelOnWaypointConstraintPayload(
  createConstraintPayload: AdminAPITypes.CreateConstraintPayload
): createConstraintPayload is AdminAPITypes.CreateTimetravelOnWaypointConstraintPayload {
  return (
    createConstraintPayload.type ===
    AdminAPITypes.EConstraintType.timetravelOnWaypoint
  );
}

export function isFeatureBundle(
  feature: AdminAPITypes.IFeature | AdminAPITypes.IFeatureBundle
): feature is AdminAPITypes.IFeatureBundle {
  return !!(feature as AdminAPITypes.IFeatureBundle).features;
}

export function isAdmUser(
  obj: AdminAPITypes.IAdmUser | AdminAPITypes.IAdmCompany
): obj is AdminAPITypes.IAdmUser {
  return !!(obj as AdminAPITypes.IAdmUser).companies;
}

export function isAmbiguousTargetGroupError(
  error: unknown
): error is CoreAPITypes.IAmbiguousTargetGroupError {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- no other workaround for typing error exists
  return (error as any)?.error_v2 === "ambiguous_target_group";
}
