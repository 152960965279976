import { Divider, ListSubheader } from "@mui/material";
import { HBSelect } from "components/hb-customs/hb-select";
import { HBMenuItems } from "components/hb-customs/hb-menu-items";
import { useFeaturesContext } from "contexts/features/features-context";
import React from "react";
import { HBListItemText } from "./hb-list-item-text";
import { AdminAPITypes } from "@stellar/api-logic";

export function FeatureSelect(): JSX.Element {
  const featuresContext = useFeaturesContext();

  const {
    selectedFeatureOrBundleId,
    setSelectedFeatureOrBundleId,
    features,
    featureBundles,
  } = featuresContext;

  const sortedBundles = featureBundles.reduce<AdminAPITypes.IFeatureBundle[]>(
    (bundles, bundle) =>
      bundle.identifier === AdminAPITypes.EFeatureBundleIdentifier.vpm
        ? [bundle, ...bundles]
        : [...bundles, bundle],
    []
  );

  return (
    <HBSelect
      label="Product"
      value={selectedFeatureOrBundleId ?? ""}
      onChange={(event) => setSelectedFeatureOrBundleId(event.target.value)}
      variant="outlined"
      isFullWidth
      isRequired
    >
      <ListSubheader>Bundles</ListSubheader>
      {HBMenuItems({
        menuItemInputs: sortedBundles.map(({ name, description, id }) => ({
          label: <HBListItemText primary={name} secondary={description} />,
          value: id,
        })),
      })}
      {features.length > 0 && <Divider />}
      {features.length > 0 && <ListSubheader>Features</ListSubheader>}
      {HBMenuItems({
        menuItemInputs: features.map(({ name, description, id }) => ({
          label: <HBListItemText primary={name} secondary={description} />,
          value: id,
        })),
      })}
    </HBSelect>
  );
}
