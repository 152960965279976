import { ListItemText, ListItemTextProps } from "@mui/material";
import React from "react";
import { Color } from "utils/styles/default-colors";

interface IProps {
  /** Primary text shown in the first line */
  primary: ListItemTextProps["primary"];

  /** Secondary text shown in a second line */
  secondary: ListItemTextProps["secondary"];
}

/** Renders a list item text with a primary and a secondary text below. */
export function HBListItemText({ primary, secondary }: IProps): JSX.Element {
  return (
    <ListItemText
      sx={{
        margin: "0px",
        "& .MuiListItemText-secondary": {
          color: Color.grey39,
        },
      }}
      {...{ primary, secondary }}
    />
  );
}
